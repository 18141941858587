<template>
  <div
    class="rent-ta-form"
    :style="{ '--ta-sticky-offset': `${stickyOffset}px` }"
  >
    <div class="col-12">
      <tenant
        ref="tenantForm"
        :tenantData="taTenant"
        :isLoading.sync="isTenantLoading"
        :isEdit="isEdit"
        required
        @update:tenantData="
          (val) => {
            this.$emit('update:taTenant', val);
          }
        "
      ></tenant>
    </div>
    <div class="col-12">
      <landlord
        ref="landlordForm"
        :landlordData="taLandlord"
        :isLoading.sync="isLandlordLoading"
        :isEdit="isEdit"
        required
        @update:landlordData="
          (val) => {
            this.$emit('update:taLandlord', val);
          }
        "
      ></landlord>
    </div>
    <div class="col-12">
      <general
        ref="generalForm"
        :generalData="taGeneral"
        :isLoading.sync="isGeneralLoading"
        :isEdit="isEdit"
        :required="required"
        @update:generalData="
          (val) => {
            this.$emit('update:taGeneral', val);
          }
        "
      ></general>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    General: () => import("./General"),
    Tenant: () => import("./Tenant"),
    Landlord: () => import("./Landlord")
  },
  mixins: [],
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    taGeneral: {
      type: Object
    },
    taTenant: {
      type: Object
    },
    taLandlord: {
      type: Object
    },
    required: {
      type: Boolean,
      default: false
    },
    stickyOffset: {
      default: 0
    }
  },
  data: function () {
    return {
      isTenantLoading: false,
      isLandlordLoading: false,
      isGeneralLoading: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.$watch(
      () =>
        this.isTenantLoading || this.isLandlordLoading || this.isGeneralLoading,
      (val) => {
        this.$emit("update:isLoading", val);
      },
      {
        immediate: true
      }
    );
  },
  methods: {
    submitAll() {
      if (
        this.$refs.tenantForm.checkValidation() &&
        this.$refs.landlordForm.checkValidation() &&
        this.$refs.generalForm.checkValidation()
      ) {
        let data = {
          tenantInfo: this.taTenant,
          landlordInfo: this.taLandlord,
          tenancyAgreement: this.taGeneral
        };

        this.$emit("submit", data);

        return data;
      } else {
        throw "[TAForm] Validation failed";
      }
    }
  }
};
</script>

<style lang="scss">
.sticky-element {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  overflow-anchor: none;
  top: var(--ta-sticky-offset);
  z-index: 1;
  background: white;
  box-shadow: 0px 4px 10px #00000015;
  border-radius: 4px;
}
.error-ind {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
